// Check If Element Is In Viewport
export const isInViewport = (element: HTMLElement): boolean => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= ((window.innerHeight + rect.height) || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

// Make The Page Scroll To The Top
export const scrollToTop = (): void => {
    window.scrollTo({ top: 0, behavior: "smooth" });
}
